import React, { useState, useCallback } from 'react';
import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';
import mariage_5 from '../images/8f09c8e8-ebc8-4fa9-9499-b08ec9e8396d.JPG';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 41.923667083414465,
  lng: 8.729277049198213
};

const legendStyle = {
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  backgroundColor: 'white',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  zIndex: 1000, // Assure qu'elle reste au-dessus de la carte
  fontSize: '14px',
  lineHeight: '1.5',
};

const iconStyle = (color) => ({
  display: 'inline-block',
  width: '12px',
  height: '12px',
  backgroundColor: color,
  borderRadius: '50%',
  marginRight: '5px',
});

// POI data
const locations = [
  { 
    key: 'Lieu du Mariage', 
    location: { lat: 41.9674087, lng: 8.6366284 },
    info: 'Domaine de Sallicia, 20167 Villanova.'
  },
  { 
    key: 'Hébergement recommandé 1', 
    location: { lat: 41.98369, lng: 8.67048 },
    info: 'Residence de Paese di lava',
    text: 'Si vous restez 3 nuits minimum: il y a des logements à 90€/ nuit. C’est au bord de l’eau et à 15 mins en voiture du lieu. Des navettes seront mis à disposition pour vous ramener à cette résidence.',
    link: 'https://paese-di-lava.com'
  },
  { 
    key: 'Hébergement recommandé 2', 
    location: { lat: 41.99160, lng: 8.67069 },
    info: 'hôtel Marina di lava',
    text: 'Si vous restez moins de 3 nuits, il y des logements à 120€ la nuit avec vue magnifique. C’est à 40 mins en voiture du lieu (option 1) et à seulement 15 mins à pieds par la plage de la résidence Paese di lava (option 2)',
    link: 'https://marinadilava.thais-hotel.com/direct-booking/search'
  },
  { 
    key: 'Hébergement recommandé 3', 
    location: { lat: 41.96436, lng: 8.72662 },
    info: 'Bergeriee d\'Alata',
    text: '30 mins en voiture du lieu.',
    link: 'https://www.lesbergeriesdalata.com/'
  },
];

const Googlemap = () => {
  const [mapCenter, setMapCenter] = useState(center);
  const [mapZoom, setMapZoom] = useState(11);

  return (
    <APIProvider apiKey="AIzaSyBB7MyXGdSpExnHtVwkdRk4cY0YHf5ANVw" onLoad={() => console.log('Maps API loaded successfully!')}>
      <Map
        zoom={mapZoom}
        center={mapCenter}
        style={containerStyle}
        mapId="f06a6183e6f3e6e9"
        onCameraChanged={(ev) => {
          console.log('Camera changed:', ev.detail.center, 'Zoom:', ev.detail.zoom);
          setMapCenter(ev.detail.center);
          setMapZoom(ev.detail.zoom);
        }}
      >
        <PoiMarkers pois={locations} />
      </Map>
      <div style={legendStyle}>
        <h4>Légende :</h4>
        <p>
          <span style={iconStyle('#4CAF50')}></span> Lieu du Mariage
        </p>
        <p>
          <span style={iconStyle('#FF5733')}></span> Hébergements Recommandés
        </p>
      </div>
    </APIProvider>
  );
};

const PoiMarkers = ({ pois }) => {
  const [selectedPoi, setSelectedPoi] = useState(null); // Track selected POI
  const markerRefs = {}; // Object to store refs for each marker

  // Callback ref for markers
  const setMarkerRef = useCallback((key, marker) => {
    if (marker) {
      console.log(`Marker instance created for: ${key}`, marker); // Log marker instance
      markerRefs[key] = marker; // Save the marker instance
      marker.addListener('click', () => {
        console.log(`Marker clicked: ${key}`); // Log click
        setSelectedPoi(pois.find((poi) => poi.key === key)); // Show info window
      });
    } else {
      console.warn(`Marker instance is null for key: ${key}`); // Log if marker is null
    }
  }, []);

  return (
    <>
      {pois.map((poi) => (
        <AdvancedMarker
          key={poi.key}
          ref={(marker) => setMarkerRef(poi.key, marker)} // Use callback ref
          position={poi.location}
        >
          <Pin 
            background={poi.key === 'Lieu du Mariage' ? "#4CAF50" : "#FF5733"}
            borderColor={poi.key === 'Lieu du Mariage' ? "#4CAF50" : "#FF5733"}
            glyphColor={"#FFFFFF"}
          />
        </AdvancedMarker>
      ))}

      {/* Info Window */}
      {selectedPoi && (
        <InfoWindow 
          position={selectedPoi.location} 
          onCloseClick={() => setSelectedPoi(null)}
        >
        {selectedPoi.key === 'Lieu du Mariage' ? (
          <div>
            <p style={{color: 'black'}}>
              <h3>{selectedPoi.key}</h3>
              <div>{selectedPoi.info}</div>
              <a href='https://www.google.fr/maps/place/Saliccia,+20167+Villanova/@41.9677057,8.637809,17z/data=!4m10!1m2!2m1!1sdomaine+de+saliccia!3m6!1s0x12da66408b4b5abd:0xcd6da3b8e080433!8m
2!3d41.967614!4d8.636688!15sChNkb21haW5lIGRlIHNhbGljY2lhWhUiE2RvbWFpbmUgZGUgc2FsaWNjaWGSAQ9jb2xsb3F1aWFsX2FyZWHgAQA!16s%2Fg%2F1v_hw48n?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D'
            >Cliquer ici pour calculer votre itinéraire avec Google Map.</a>
            </p>
            <p>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <img
                  src={mariage_5}
                  alt="mariage-5" 
                  style={{ 
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain'
                  }}
                />
              </div>
            </p>
          </div>
        ) : (
          <div>
            <p style={{color: 'black'}}>
            <h3>{selectedPoi.key}</h3>
            <div>{selectedPoi.info}</div>
            <p>{selectedPoi.text}</p>
            <a href={selectedPoi.link}>website - {selectedPoi.info}</a>
            </p>
          </div>
        )}
        </InfoWindow>
      )}
    </>
  );
};

export default Googlemap;
